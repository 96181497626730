import $ from "jquery";

// ハンバーガーメニュー
const active = '-active';
$('#js-hamburger').on('click', (event) => {
  $(event.currentTarget).toggleClass(active);
  $('#js-hamburgerInner').toggleClass(active);
  $('.js-hamburgerLine').toggleClass(active);
  $('#js-sitemap').toggleClass(active);
  $('#js-tool').toggleClass(active);
  $('#js-wrap').toggleClass('-disable');

  return false;
});
