import $ from "jquery";
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';

// 共通設定
const newsSwiperWrap = '#js-newsSwiper',
      newsSwiper = newsSwiperWrap + ' .swiper-container',
      param = {

      };

// 共通設定の読み込み
Swiper.use(param);

// スライドの数を数える
let cnt = $(newsSwiper + ' .swiper-slide').length;

// スライドの数によってswiperの設定を変える
let swiper;

if(cnt > 1) {
  swiper = new Swiper(newsSwiper, {
    loop: true,
    navigation: {
      nextEl: newsSwiperWrap + ' .swiper-button-next',
      prevEl: newsSwiperWrap + ' .swiper-button-prev',
    },
    slidesPerView: 1,
    spaceBetween: 0
  });
} else {
  $(newsSwiperWrap + ' .swiper-button-next').css('display', 'none');
  $(newsSwiperWrap + ' .swiper-button-prev').css('display', 'none');
}
