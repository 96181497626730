import $ from "jquery";
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';

setTimeout(function() {
  const swiper = new Swiper('#js-heroSwiper .swiper-container', {
    autoplay: {
      delay: 2000,
    },
    effect: 'fade',
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,
    speed: 2000,
  });
}, 2500);
